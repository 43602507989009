import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listResponsiblePerson: [],
  listResponsiblePersonForSelect: [],
  listCheckedPerson: [],
};

const urlPrefix = "responsiblePerson";

const actions = {
  async getAllResponsiblePerson(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListResponsiblePerson", response.data);
    });
  },

  async searchResponsiblePerson(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListResponsiblePerson", response.data);
    });
  },

  async getListResponsiblePersonForSelect(context, units) {
    return await AXIOS.get(urlPrefix + "/search/select?selectedIds=" + units).then(
      (response) => {
        console.log(response.data);
        context.commit("setListResponsiblePersonForSelect", response.data);
      }
    );
  },

  async getCheckedPersonForSelect(context) {
    return await AXIOS.get(urlPrefix + "/select/checked").then(
      (response) => {
        context.commit("setCheckedPersonForSelect", response.data);
      }
    );
  },

  async createResponsiblePerson(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateResponsiblePerson(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteResponsiblePerson(context, id) {
    return await AXIOS.get(urlPrefix + "/delete?personId=" + id);
  },
};

const mutations = {
  setListResponsiblePerson(state, data) {
    state.listResponsiblePerson = data;
  },

  setListResponsiblePersonForSelect(state, data) {
    state.listResponsiblePersonForSelect = data;
  },

  setCheckedPersonForSelect(state, data) {
    state.listCheckedPerson = data;
  },
};

const getters = {
  GETLISTRESPONSIBLEPERSON: (state) => state.listResponsiblePerson,
  GETLISTCHECKEDPERSON: (state) => state.listCheckedPerson,
  GETLISTRESPONSIBLEPERSONFORSEARCH: (state) =>
    state.listResponsiblePersonForSelect,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
