import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAdministrativeDocument: [],
  listDocumentNumbers: [],
};

const urlPrefix = "report";

const actions = {
  async downloadPod10Report(context, dataReport) {
    let data=dataReport.data;
     AXIOS({
      url:urlPrefix + "/get/pod10", 
      data: {formData:data.formData,reportname:dataReport.reportName},
      method: "POST",
      responseType: "blob",
      timeout: 300000,
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  },

  async downloadAnyReports(context, dataReport) {
     AXIOS({
      url:urlPrefix + "/get/repany", 
      data: dataReport,
      method: "POST",
      responseType: "blob",
      timeout: 300000,
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", dataReport.reportName+dataReport.fileType); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  },

  async downloadEpp(context, dataReport) {
    
    console.log(dataReport);
     AXIOS({
      url:urlPrefix + "/get/epp", 
      data: dataReport,
      method: "POST",
      responseType: "blob",
      timeout: 300000,
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "epp"+dataReport.fileTypes); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  },

  async downloadMainReport(context, dataReport) {
    let data=dataReport.data;
     AXIOS({
      url:urlPrefix + "/get/mainReport", 
      data: {formData:data.formData,reportname:dataReport.reportName},
      method: "POST",
      responseType: "blob",
      timeout: 300000,
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  },

  async getPasstortValues(context, passportID)
  {
    return await AXIOS.get(
      "report/get/passportValues?passportID="+passportID
      ).then((response) => {
        context.commit("setPassportValues", response.data);
      });
  },
  downloadFile(context,file) {
    if (file.i < 100)
      AXIOS({
        url: urlPrefix + "/download?filePath=" + file.filePath+"&fileType="+file.fileType, //your url
        method: "GET",
        responseType: "blob", // important
      }).catch((error) => {
        console.log(error);
        if(file.i<100)
        {
          setTimeout(() => {
            context.dispatch('downloadFile', { filePath: file.filePath, fileType: file.fileType, fileName: file.fileName, i: file.i + 1 });
          }, 60000);
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

  async downloadWaste1Report(context, data) {
    AXIOS({
     url:urlPrefix + "/get/Waste1", 
     data: data.formData,
     method: "POST",
     responseType: "blob",
     timeout: 300000,
   }).catch((error) => {console.log(error);
    let strName='';
    if (data.formData.date>2023)
      strName='Waste-1-2024';
    else
      strName='Waste-1';
      context.dispatch('downloadFile', { filePath: strName, fileType: data.formData.fileType, fileName: data.fileName, i:1 });
    return;})
   .then(
     (response) => {
        
      console.log("response");
      console.log(response);
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", data.fileName); //or any other extension
       document.body.appendChild(link);
       link.click();
     }
   );
 },
  async downloadPod9Report(context, dataReport) {
    let data=dataReport.data;
    AXIOS({
      url: urlPrefix + "/get/pod9", //your url
      data: {formData:data,reportname:dataReport.reportName},
      method: "POST",
      responseType: "blob",
      timeout: 300000, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Под-9" + data.fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },

  async getOrganizationWastesListByOrganizationIdAndDateTwo(context, data) {
    return await AXIOS.get("organization-waste/get/organization/wastesdatetwo?startDate=" + data.startDate + "&endDate="+data.endDate).then(
      (response) => {
        context.commit("setListAllOrganizationWastes", response.data);
      }
    );
  },

  async downloadPassport(context, data) {
    AXIOS({
      url: urlPrefix + "/get/passport", //your url
      data: data,
      method: "POST",
      responseType: "blob",
      timeout: 300000, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Сопроводительный паспорт" + data.fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },

  async deleteReport(context, data) {
    return await AXIOS({
      url: urlPrefix + "/delete/report", //your url
      data: {deleteID:data},
      method: "POST",
      responseType: "blob",
      timeout: 300000, // important
    });
  },

  async updatePassport(context, data) {
    return await AXIOS({
      url: urlPrefix + "/update/passport", //your url
      data: data,
      method: "POST",
      responseType: "blob",
      timeout: 300000, // important
    });
  },

  async downloadLogPassport(context, data) {
    AXIOS({
      url: urlPrefix + "/get/logpassport", //your url
      data: data,
      method: "POST",
      responseType: "blob",
      timeout: 300000, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Журнал регистрации сопроводительных паспортов" + data.fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
};



const mutations = {

  setPassportValues(state, data) {
    state.passportValues = data;
  },

};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
