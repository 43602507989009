import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAllAreas: [],
};

const urlPrefix = "classifiers/subClassifiers/areas";

const actions = {
  async getListAllAreas(context, data) {
    return await AXIOS.get(
      urlPrefix + "/?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListAllAreas", response.data);
    });
  },

  async createArea(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateArea(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteArea(context, data) {
    return await AXIOS.post(urlPrefix + "/delete", {
      data: data,
    });
  },
};

const mutations = {
  setListAllAreas(state, data) {
    state.listAllAreas = data;
  },
};

const getters = {
  GETLISTALLAREAS: (state) => state.listAllAreas,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
