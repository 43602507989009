import { AXIOS } from "@/AXIOS/http-common";

const state = {
  ss01dictionary: [],
  ss01CodeList: [],
  wasteNames: [],
};

const urlPrefix = "classifiers/ss01dictionary";

const actions = {
  async getSs01Dictionary(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response);
      context.commit("setSs01Dictionary", response.data);
    });
  },

  async getCodeList(context, query) {
    return await AXIOS.get(urlPrefix + "/getCodes?inputValue=" + query).then(
      (response) => {
        console.log(response);
        context.commit("setSs01codeList", response.data);
      }
    );
  },

  async getWasteNames(context, query) {
    return await AXIOS.get(
      urlPrefix + "/getWasteNames?inputValue=" + query
    ).then((response) => {
      console.log(response);
      context.commit("setWasteNames", response.data);
    });
  },

  async deleteSs01(context, id) {
    return await AXIOS.get(urlPrefix + "/delete?classifierId=" + id);
  },

  async findSS01DictionaryValueByClassifierId(context, id) {
    return await AXIOS.get(
      urlPrefix + "/findByClassifier?classifierId=" + id
    ).then((response) => {
      console.log(response);
      let data = {
        content: [],
      };

      data.content.push(response.data);
      context.commit("setSs01Dictionary", data);
    });
  },

  async SS01DictionaryValueSum (context, id) {
    return await AXIOS.get(
      urlPrefix + "/SS01DictionaryValueSum?Id=" + id
    ).then((response) => {
      console.log(response);
      let data = {
        content: [],
      };

      data.content.push(response.data);
      context.commit("SS01DictionaryValueSum", data);
    });
  },

  async findSS01DictionaryValueByDangerDegreeId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/findByDangerDegree?dangerDegreeId=" +
        data.dangerDegreeId +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setSs01Dictionary", response.data);
    });
  },

  async createWasteClassifier(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateWasteClassifier(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },
};

const mutations = {
  setSs01Dictionary(state, data) {
    state.ss01dictionary = data;
  },

  setSs01codeList(state, data) {
    state.ss01CodeList = data;
  },

  setWasteNames(state, data) {
    state.wasteNames = data;
  },
};

const getters = {
  GETSS01DICTIONARY: (state) => state.ss01dictionary,
  GETSSS01CODELIST: (state) => state.ss01CodeList,
  GETWASTENAMES: (state) => state.wasteNames,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
