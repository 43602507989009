import { AXIOS } from "@/AXIOS/http-common";

const state = {
  usersList: [],
  managerList: [],
  usersByManager: [],
  OrganizationForManagerList: [],
};

const urlPrefix = "/admin/user";

const actions = {
  async getAllUsers(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setUsersList", response.data);
    });
  },

  async searchUserByOrganizationId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/find/" +
        "?organizationId=" +
        data.id +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setUsersList", response.data);
    });
  },

  async searchUsersForManager(context, data) {
    console.log(data);
    return await AXIOS.get(
      urlPrefix +
        "/findForManagerSelector?organizationId=" +
        data.organizationId +
        "&roleId=" +
        data.roleId
    ).then((response) => {
      console.log(response.data);
      context.commit("setManagerList", response.data);
    });
  },

  async searchOrganizationForManager(context, data) {
    console.log(data);
    return await AXIOS.get(
      "/user/organization/hierarchy?organizationId=" +
        data.organizationId
    ).then((response) => {
      console.log(response.data);
      context.commit("setOrganizationForManagerList", response.data);
    });
  },

  async getUsersByManager(context, data) {
    console.log(data);
    return await AXIOS.get(urlPrefix + "/get/users/by/manager").then(
      (response) => {
        console.log(response.data);
        context.commit("setUsersByManager", response.data);
      }
    );
  },

  async addUser(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async editUser(context, data) {
    return await AXIOS.put(urlPrefix + "/edit", data);
  },

  async deleteUserById(context, userId) {
    return await AXIOS.get(urlPrefix + "/delete?userId=" + userId);
  },
};

const mutations = {
  setUsersList(state, data) {
    state.usersList = data;
  },

  setOrganizationForManagerList(state, data) {
    state.OrganizationForManagerList = data;
  },

  setManagerList(state, data) {
    state.managerList = data;
  },

  setUsersByManager(state, data) {
    state.usersByManager = data;
  },
};

const getters = {
  GETORGANIZTIONFORMANAGERLIST: (state) => state.OrganizationForManagerList,
  GETUSERSLIST: (state) => state.usersList,
  GETMANAGERLIST: (state) => state.managerList,
  GETUSERSBYMANAGER: (state) => state.usersByManager,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
