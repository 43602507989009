<template>
  <div style="box-shadow: 0 0 10px rgba(200, 200, 200, 0.5)">
    <el-menu
      v-if="authUser !== null && authUser.role !== 'ADMIN'"
      :router="true"
      class="el-menu-demo"
      mode="horizontal"
    >
      <!-- v-if=" authUser.lvldostup !== null && (authUser.lvldostup == 0 ||
      authUser.lvldostup == 1) " -->
      <el-menu-item index="/natResources/main">
        <img style="width: 75px" alt="Vue logo" src="../assets/logo.png" />
      </el-menu-item>

      <el-menu-item
        v-if="
          (authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1)) && (userID != 293 && userID != 503)
        "
        index="/dataEntry/main"
      >
        Внесение данных
      </el-menu-item>

      <el-submenu
        index="/"
        v-if="
          (authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1) && authUser.role.id == 1) && (userID != 293 && userID != 503)
        "
      >
        <template slot="title">Настройки</template>
        <el-menu-item index="/natResources/main"
          >Данные о природопользователе</el-menu-item
        >
        <el-menu-item index="/branches/main">Филиалы</el-menu-item>
        <el-menu-item index="/subdivisions/main">Подразделения</el-menu-item>
        <el-menu-item index="/districts/main">Участки</el-menu-item>

        <el-menu-item index="/wasteproduction/main"
          >Отходы производства</el-menu-item
        >
        <el-menu-item index="/firstEncounter/main"
          >Данные для первого входа</el-menu-item
        >
        <el-menu-item index="/limits/main"
          >Лимиты</el-menu-item
        >
        <el-menu-item index="/techprocesses/main">Тех. процессы</el-menu-item>
        <el-menu-item index="/classifiers/main">Справочники</el-menu-item>
      </el-submenu>

      <el-submenu
        index=" /"
        v-if="
          (authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1) && authUser.role.id == 1) && (userID == 25 || userID == 427
           || userID == 124 || userID == 14  || userID == 25  || userID == 559)
        "
      >
      <template slot="title">Лимиты</template>
        <el-menu-item index="/limitsresearch/lim1"
          >Транспортные</el-menu-item
        >
        <el-menu-item index="/limitsresearch/lim2"
          >Хранение</el-menu-item
        >
        <el-menu-item index="/limitsresearch/lim3"
          >Захоронение</el-menu-item
        >
      </el-submenu>

      <el-menu-item
        v-if="
          authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1) && (userID != 503)
        "
        index="/data-agreement/main"
      >
        Согласование данных
      </el-menu-item>
      <el-menu-item
        v-if="
          authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1) && (userID != 293)
        "
        index="/reports/main"
      >
        Отчеты
      </el-menu-item>
      <el-menu-item
        v-if="
          authUser.lvldostup !== null &&
          (authUser.lvldostup == 0 || authUser.lvldostup == 1) && (userID != 293)
        "
        index="/disposal/main"
      >
        Вывоз отходов
      </el-menu-item>
      <el-menu-item
        v-if="
          authUser !== null &&
          (authUser.lvldostup == 0 ||
            authUser.lvldostup == 1 ||
            authUser.lvldostup !== 4) && (userID != 293)
        "
         index="/rof/table" style="float: right"> Отметка реестра </el-menu-item>
      <el-menu-item
        v-if="
          authUser !== null &&
          (authUser.lvldostup == 0 ||
            authUser.lvldostup == 1 ||
            authUser.lvldostup !== 4) && (userID != 293 && userID != 503)
        "
        style="float: right"
        index="/registerOfFacilities"
      >
      
        <i class="el-icon-map-location"></i>
        Реестр
      </el-menu-item>

      <el-submenu
        v-if="
          authUser !== null &&
          (
            authUser.lvldostup == 1 ||
            authUser.lvldostup == 2 ||
            authUser.lvldostup == 4 )
        "
        style="float: right"
        index="/wasteExchange"
      >
        <template slot="title">
          <i class="el-icon-money"></i>
          Биржа</template>
          
        <el-menu-item index="/wasteExchange/main"
          >Мои лоты
        </el-menu-item>
        <el-menu-item index="/wasteExchange/mainconfirm"
          >Архив моих лотов
        </el-menu-item>
        <el-menu-item index="/wasteExchange/search"
          >Все лоты
        </el-menu-item>
        <el-menu-item index="/wasteExchange/bed">Мои заявки на лоты</el-menu-item>
        <el-menu-item index="/wasteExchange/bedconfirm">Архив моих заявок на лоты</el-menu-item>
        <el-menu-item index="/wasteExchange/maps">Лоты на карте</el-menu-item>
      </el-submenu>

      <el-menu-item v-if="authUser === null" style="float: right" index="/auth">
        <i class="el-icon-user-solid"></i>
        Вход
      </el-menu-item>
      <el-submenu style="float: right" v-else index="/profile/info">
        <template slot="title">
          <i class="el-icon-user-solid"></i>
          {{ authUser.username }}</template
        >
        <el-menu-item @click="logout">Выход</el-menu-item>
        <el-menu-item index="/profile/info">Профиль</el-menu-item>
      </el-submenu>
      <el-menu-item style="float: right">
        <el-popover placement="bottom-end" width="900" trigger="hover">
          <div slot="reference">
            <i class="el-icon-bell"></i>
            <el-badge
              v-if="countMessage !== 0"
              style="right: -5px; position: absolute; bottom: 6px"
              :value="countMessage"
            >
            </el-badge>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in licenseMessage">
              {{ message.name }}
            </p>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in permissionMessage">
              {{ message.name }}
            </p>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in storeMessage">
              {{ message.name }}
            </p>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in regMessage">
              {{ message.name }}
            </p>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in check1LotsBid">
              {{ message.name }}
            </p>
          </div>
          <div
            style="
              font-family: Avenir, Helvetica, Arial, sans-serif;
              cursor: pointer;
            "
          >
            <p :key="key" v-for="(message, key) in check2LotsBid">
              {{ message.name }}
            </p>
          </div>
        </el-popover>
      </el-menu-item>
    </el-menu>

    <!---------------------------------------------------------->

    <el-menu
      v-if="authUser !== null && authUser.role === 'ADMIN'"
      :router="true"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item index="/organizations/main">
        <img style="width: 75px" alt="Vue logo" src="../assets/logo.png" />
      </el-menu-item>
      <el-menu-item index="/organizations/main"> Организации </el-menu-item>
      <el-menu-item index="/users/main"> Пользователи </el-menu-item>
      <el-menu-item index="/classifiers/main"> Справочники </el-menu-item>
      <el-menu-item index="/subclassifiers/main"> Подсправочники </el-menu-item>
      <el-menu-item index="/egr/main"> ЕГР </el-menu-item>
      <el-menu-item index="/registries/main"> Реестры объектов </el-menu-item>
      <el-menu-item
        v-if="authUser === null"
        style="float: right"
        index="/registerOfFacilities"
      >
        <i class="el-icon-s-claim"></i>
        Реестр
      </el-menu-item>

      
      <el-menu-item style="float: right" index="/registerOfFacilities">
        <i class="el-icon-s-claim"></i>
        Реестр
      </el-menu-item>
      <el-menu-item v-if="authUser === null" style="float: right" index="/auth">
        <i class="el-icon-user-solid"></i>
        Вход
      </el-menu-item>
      <el-submenu style="float: right" v-else index="/profile">
        <template slot="title">
          <i class="el-icon-user-solid"></i>
          ADMIN</template
        >
        <el-menu-item @click="logout">Выход</el-menu-item>
      </el-submenu>
    </el-menu>

    <el-menu
      v-if="authUser === null"
      :router="true"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item style="float: right" index="/auth">
        <i class="el-icon-user-solid"></i>
        Вход
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      userID:0,
      visible: false,
    };
  },
  name: "navMenu",
  computed: {
    ...mapGetters({
      routes: "GETROUTES",
      authUser: "GETAUTHUSER",
      licenseMessage: "GETLICENSEMESSAGE",
      permissionMessage: "GETPERMISSIONMESSAGE",
      storeMessage: "GETSTOREMESSAGE",
      regMessage: "GETREGMESSAGE",
      check1LotsBid: "GETCHECK1LOTSBID",
      check2LotsBid: "GETCHECK2LOTSBID",
    }),

    countMessage: {
      get() {
        return (
          this.licenseMessage.length +
          this.permissionMessage.length +
          this.storeMessage.length+
          this.regMessage.length+
          this.check1LotsBid.length+
          this.check2LotsBid.length
        );
      },
    },
  },

  methods: {
    async logout() {
      console.log("logout");
      Cookies.remove("eco-token");
      Cookies.remove("role");
      await this.$router.push("/auth");
      window.location.reload();
    },
    async logoutNoReload() {
      console.log("logout");
      Cookies.remove("eco-token");
      Cookies.remove("role");
      await this.$router.push("/auth");
    },
    authorization() {},
  },

  mounted() {
    this.userID=Cookies.get("userId");
    console.log(this.authUser);
    console.log("Смотрим сюда");
    console.log(Cookies.get("userId"));
    console.log(Cookies.get("role"));
    console.log(Cookies.get("eco-token"));
    console.log(Cookies.get("lvldostup"));
    if (Cookies.get("userId") == undefined && Cookies.get("role") != undefined)
      this.logout();
    console.log(this);
  },
  
};
</script>

<style scoped>
.item {
  margin-top: 10px;
  margin-right: 40px;
}
/* .navigation {
  display: flex;
  width: 75vw;
}
.el-menu.el-menu--horizontal {
  display: flex;
} */
</style>
