import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listvehicles: [],
};

const urlPrefix = "vehicles";

const actions = {
  async getAllvehicles(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListvehicles", response.data);
    });
  },

  async searchvehicles(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListvehicles", response.data);
    });
  },

  async createvehicles(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updatevehicles(context, data) {
    return await AXIOS.post(urlPrefix + "/update", data);
  },

  async deletevehicles(context, id) {
    return await AXIOS.get(urlPrefix + "/delete?documentId=" + id);
  },

  
};

const mutations = {
  setListvehicles(state, data) {
    state.listvehicles = data;
  },

};

const getters = {
  GETLISTvehicles: (state) => state.listvehicles,
  
};

export default {
  state,
  actions,
  mutations,
  getters,
};
